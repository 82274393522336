@font-face {
    font-family: "Equip";
    src: url("fonts/Hoftype\ -\ Equip-Light.otf");
}
@font-face {
    font-family: "Equip";
    src: url("fonts/Hoftype\ -\ Equip-Medium.otf");
    font-weight: bold;
}

body {
    margin: 0px;
    padding: 0px;
    background-color: "#ffffff";
    font-family: 'Equip';
}

@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}